import RoadmapCard from "components/RoadmapCard";
import styles from "scss/layout/Roadmap.module.scss";
import strip1 from "assets/images/roadmap/strip1.svg";
import strip2 from "assets/images/roadmap/strip2.svg";
import strip3 from "assets/images/roadmap/strip3.svg";
import strip4 from "assets/images/roadmap/strip4.svg";
import strip5 from "assets/images/roadmap/strip5.svg";
import strip6 from "assets/images/roadmap/strip4.svg";
import useMediaQuery from "hooks/useMediaQuery";

function Roadmap() {
  const isBellow640px = useMediaQuery("(max-width : 40em)");

  return (
    <div>
      <div className="container-wrapper">
        <header className={isBellow640px ? "mb-40px" : "mb-100px"}>
          <h1 className="text-gradient fs-48px text-center uppercase font-heading">
            ROADMAP
          </h1>
        </header>

        {!isBellow640px ? (
          <main className={styles.roadmapCards}>
            <div className={styles.left}>
              <RoadmapCard
                title="Q4 2021 ✅"
                points={[
                  "Register Company",
                  "Set Up Team",
                  "Register Domain",
                  "Develop Website",
                ]}
              />
              <RoadmapCard
                title="Q2 2022 ✅"
                stripe={strip2}
                stripeClassName={styles.stripe2}
                points={[
                "Private Sale Round 1",
                "Audit contract",
                "Strategic partnership",
				"Develop Utilities",
                ]}
              />
              <RoadmapCard
                title="Q4 2022 ✅"
                stripe={strip4}
                stripeClassName={styles.stripe4}
                points={[
				"Private Sale Round 2",
				"Team Expansion",
				"2nd Utility Launch Subscription Platform",
				"3rd Utility Launch Energy Brokerage",
                ]}
              />
			  <RoadmapCard
              title="Q2 2023"
              stripe={strip4}
              stripeClassName={styles.stripe4}
              points={[
                "Public Sale",
				"5th Utility Launch DApp",
				"Coingecko Listing",
                "Coinmarketcap Listing",
                "Launch Token",
                "Exchange Listing",
              ]}
            />
			<RoadmapCard
              title="Q4 2023"
              stripe={strip4}
              stripeClassName={styles.stripe4}
              points={[
                "Strategic Partnerships",
				"Develop NFT MARTKETPLACE",
				"7th Utility Launch DEX",
				"Property Portfolio",
              ]}
            />
			  </div>
			<div className={styles.right}>
			<RoadmapCard
                title="Q1 2022 ✅"
                stripe={strip1}
                stripeClassName={styles.stripe1}
                points={[
                "Develop Contract",
                "Seed Round",
                "Strategic partnership",
                "KYC",
                ]}
              />
              <RoadmapCard
                title="Q3 2022 ✅"
                stripe={strip3}
                stripeClassName={styles.stripe3}
                points={[
                "Strategic Partnerships",
                "Develop Utilities",
                "Develop New Website",
                "Further Advancements",
				"1st Utility Launch CVP",
                ]}
              />
              <RoadmapCard
                title="Q1 2023✅"
                stripe={strip5}
                stripeClassName={styles.stripe5}
                points={[
                "Strategic Partnerships",
				"Develop DApps",
				"4th Utility Launch CCP",
                "Investment Rewards System",
                "Further Advancements",
              ]}
              />			  
			<RoadmapCard
              title="Q3 2023"
              stripe={strip5}
              stripeClassName={styles.stripe5}
              points={[
                "Strategic Partnerships",
				"Develop DEX",
				"6th Utility Launch Voting Platform",
                "Expansion of CVP",
              ]}
            />
			  </div>
          </main>
        ) : (
          <main className={styles.roadmapCards}>
            <RoadmapCard
              title="Q4 2021 ✅"
              points={[
                "Register Company",
                "Set Up Team",
                "Register Domain",
                "Develop Website",
              ]}
            />
		
            <RoadmapCard
              title="Q1 2022 ✅"
              stripe={strip1}
              stripeClassName={styles.stripe1}
              points={[
                "Develop Contract",
                "Seed Round",
                "Strategic partnership",
                "KYC",
              ]}
            />
			
			<RoadmapCard
              title="Q2 2022 ✅"
              stripe={strip2}
              stripeClassName={styles.stripe2}
              points={[
                "Private Sale Round 1",
                "Audit contract",
                "Strategic partnership",
				"Develop Utilities",
              ]}
            />
			
            <RoadmapCard
              title="Q3 2022 ✅"
              stripe={strip3}
              stripeClassName={styles.stripe3}
              points={[
                "Strategic Partnerships",
                "Develop Utilities",
                "Develop New Website",
                "Further Advancements",
				"1st Utility Launch CVP",
              ]}
            />
			
			<RoadmapCard
              title="Q4 2022 ✅"
              stripe={strip4}
              stripeClassName={styles.stripe4}
              points={[
				"Private Sale Round 2",
				"Team Expansion",
				"2nd Utility Launch Subscription Platform",
				"3rd Utility Launch Energy Brokerage",
				
              ]}
            />
																																	
            <RoadmapCard
              title="Q1 2023✅"
              stripe={strip5}
              stripeClassName={styles.stripe5}
              points={[
                "Strategic Partnerships",
				"Develop DApps",
				"4th Utility Launch CCP",
                "Investment Rewards System",
                "Further Advancements",
              ]}
            />
			
			<RoadmapCard
              title="Q2 2023"
              stripe={strip4}
              stripeClassName={styles.stripe4}
              points={[
               	"Public Sale",
				"5th Utility Launch DApp",
				"Coingecko Listing",
                "Coinmarketcap Listing",
                "Launch Token",
                "Exchange Listing",
              ]}
            />
			<RoadmapCard
              title="Q3 2023"
              stripe={strip5}
              stripeClassName={styles.stripe5}
              points={[
                "Strategic Partnerships",
				"Develop DEX",
				"6th Utility Launch Voting Platform",
                "Expansion of CVP",
              ]}
            />
			<RoadmapCard
              title="Q4 2023"
              stripe={strip4}
              stripeClassName={styles.stripe4}
              points={[
                "Strategic Partnerships",
				"Develop NFT MARTKETPLACE",
				"7th Utility Launch DEX",
				"Property Portfolio",
              ]}
            />
          </main>
        )}
      </div>
    </div>
  );
}

export default Roadmap;
