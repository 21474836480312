import Button from "components/Button";
import styles from "scss/layout/Hero.module.scss";

function Hero() {
    return (
        <div className={styles.heroWrapper}>
            <div className="container-wrapper-2">
                <section>
                    <h1 className="font-heading fs-64px mb-20px lh-1_2">
                        Hybrid Defi {" "}
                        <span className="text-gradient font-heading">Revolution</span>
                    </h1>

                    <p className="fs-14px white lh-1_9 mb-40px">
                        At Owners Unity we offer our investors up to nine passive income streams from multiple revenue
                        generating utilities. That's nine ways to maximise short or long term profit objectives. The
                        unique structuring of the rewards means even in a bear market, you can receive substantial
                        stable coin rewards.
                        <br/>
                        <br/>
                        Owners Unity provides a blend of real world and crypto based utilities to make this possible
                        whilst also looking to change the way the multiple real world industries operate.
                    </p>

                    <div className={styles.buttons}>
                        <Button title="BUY OUT" variant="pink"/>
                        <a href="https://ownersunity-1.gitbook.io/whitepaper/" target="_blank">
                            <Button title="Whitepaper"/>
                        </a>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Hero;
