import PropertyCard from "components/PropertyCard";
import styles from "scss/layout/PropertyOwnership.module.scss";
import banner from "assets/images/property/banner.mp4";
import PropertyCards from "components/PropertyCards";
import PropertyCardccp from "components/PropertyCardccp";

function PropertyOwnership() {
  return (
    <div className={styles.containerWrapper}>
       

      <div className="container-wrapper-2">
        <div className={styles.container}>
          <header className="mb-55px">
            <h1 className="font-heading text-gradient fs-48px weight-7 uppercase mb-15px">
              Financial Freedom in a decentralized age
            </h1>

            <p className="fs-14px white lh-1_9 weight-4">
              Building a framework in the Decentralized Finance space, Owners
              Unity Token gives the power back to the holders. Owners Unity provides long term stability,
              trust and wealth through Decentralized Finance. All products in the OUT ecosystem provide passive income to holders
            </p>
          </header>

          <main>
            <PropertyCard
              title="CVP"
              description="Client Verification Protocol (CVP) is a new utility from Owners Unity. The tool aims to replace the present industry norm of KYC processes."
            />
            <PropertyCards
              title="OWNERS UTILITY"
              description="Owners Utility offer a convenient and client-focused approach. We see your business as our business, assisting you with all business variables and allowing us to really champion the principles that matter, like your time, energy and money. Owners Utility looks to the future and aims to be at the forefront of energy innovation."
            />
            <PropertyCardccp
              title="CONTENT CREATOR PLATFORM"
              description="A Premium Subscription platform for world class Models, Influencers and Creators to monetise their fanbase and content."
            />
          </main>
        </div>
      </div>
    </div>
  );
}

export default PropertyOwnership;
