import styles from "scss/layout/AboutUs.module.scss";
import scott from "assets/images/team/scott.png";
import video from "assets/videos/about/banner.mp4";

function AboutUs() {
    return (
        <div className="container-wrapper-2">
            <div className={styles.wrapper}>
                <header>
                    <h1 className="font-heading text-gradient text-center fs-48px mb-20px uppercase">
                        About Us
                    </h1>
                    <p className="fs-14px white text-center lh-1_9">
                        OUT looks to empower its holders to reimagine the way crypto interacts with
                        traditional business. By doing this OUT looks to re-educate every day people by
                        showing the tangible benefits, crypto and blockchain can provide to make
                        everyday life simpler. This is why crypto was invented and this is how it
                        should be used.
                    </p>
                </header>

                <main className="flex">
                    <video
                        playsInline
                        autoPlay
                        muted
                        controls
                        className="w-full"
                    >
                        <source
                            src={video}
                            type="video/mp4"
                        />
                    </video>
                </main>

                <footer>
                    <img src={scott} alt=""/>

                    <div>
                        <blockquote className="text-gradient-2 fs-14px italic weight-6 text-center mb-15px">
                            “ OUR goals are to help people achieve financial freedom,
                            through education and understanding of blockchain technology,
                            to make the future secure and return the power to you, the people. ”
                        </blockquote>

                        <div>
                            <p className="fs-12px white text-center weight-4">Scott Scherer</p>
                            <p className="fs-12px weight-7 text-center white">Founder</p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default AboutUs;
