import React from "react";
import styles from "scss/layout/Footer.module.scss";
import logo from "assets/images/logo.png";
import { FaTwitter, FaFacebookF, FaLinkedinIn, FaGithub, FaTelegram, FaDiscord, FaReddit } from "react-icons/fa";

function Footer() {
  return (
    <div className={styles.wrapper}>
      <div className="container-wrapper-2">
        <div className={styles.footer}>
          <main>
            <div>
              <img src={logo} alt="" />
            </div>
			
            <ul>
              <li className="fs-16px white weight-7 mb-40px">Quick Links</li>
              <li>
                <a
                  href="#tokenomics"
                  className="fs-14px white opacity-0_8 pointer mb-40px block w-fit-content"
                >
                  Tokenomics
                </a>
              </li>
              <li>
                <a
                  href="#aboutus"
                  className="fs-14px white opacity-0_8 pointer mb-40px block w-fit-content"
                >
                  About Us
                </a>
              </li>
            </ul>
			
			<ul>
			
			<li className="fs-16px white weight-7 mb-40px">Documents</li>
			<li>
			<a href="https://ownersunity-1.gitbook.io/whitepaper/" className="fs-14px white opacity-0_8 pointer mb-40px block w-fit-content" target="_blank">
            Whitepaper
            </a>
			</li>
			<li>
			<a href="https://github.com/interfinetwork/project-delivery-data/blob/main/Owners%20Unity/OwnersUnity_AuditReport_InterFi.pdf" className="fs-14px white opacity-0_8 pointer mb-40px block w-fit-content" target="_blank">
            Audit
            </a>
			</li>
			<li>
			<a href="https://github.com/Salz123/resources/blob/main/Compliance_Cert.jpg" className="fs-14px white opacity-0_8 pointer mb-40px block w-fit-content" target="_blank">
            Compliance Certificate
            </a>
			</li>
			<li>
			<a href="https://github.com/Salz123/resources/blob/main/Pitch-Deck.pdf" className="fs-14px white opacity-0_8 pointer mb-40px block w-fit-content" target="_blank">
            Pitch Deck
            </a>
			</li>
			</ul>
			
            <ul>
              <li className="fs-16px white weight-7 mb-40px">Links</li>
              <li>
                <a
                  href="#"
                  className="fs-14px white opacity-0_8 pointer mb-40px block w-fit-content"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#roadmap"
                  className="fs-14px white opacity-0_8 pointer mb-40px block w-fit-content"
                >
                  Roadmap
                </a>
              </li>
            </ul>
          </main>
          <footer>
            <div className={styles.socialIcons}>
              <a href="https://twitter.com/OwnersUnity" target="_blank" className="fs-20px">
                <FaTwitter />
              </a>
			  <a href="https://t.me/OGoutoken" target="_blank" className="fs-20px">
			  <FaTelegram />
			  </a>
			  <a href="https://discord.gg/nP2PpqTv" target="_blank" className="fs-20px">
			  <FaDiscord />
			  </a>
              <a href="https://www.linkedin.com/company/owners-unity/" target="_blank" className="fs-20px">
                <FaLinkedinIn />
              </a>
              <a href="https://www.facebook.com/OwnersUnityToken" target="_blank" className="fs-20px">
                <FaFacebookF />
              </a>
			  <a href="https://github.com/Owners-Unity-Token" target="_blank" className="fs-20px">
			  <FaGithub />
			  </a>
			  <a href="https://www.reddit.com/r/OwnersUnity_Token" target="_blank" className="fs-20px">
			  <FaReddit />
			  </a>
            </div>
            <a href="https://ownersunity-1.gitbook.io/terms-and-conditions/" target="_blank" className="white opacity-0_8 pointer fs-14px weight-4">
              Terms and Conditions
            </a>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Footer;
