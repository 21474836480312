import TeamCard from "components/TeamCard";
import React from "react";
import styles from "scss/layout/Team.module.scss";

import scott from "assets/images/team/scott.png";
import harvey from "assets/images/team/harvey.png";
import earl from "assets/images/team/earl.png";
import dalton from "assets/images/team/dalton.png"
import dravo from "assets/images/team/dravo.png"
import paul from "assets/images/team/paul.png";

import simon from "assets/images/team/simon.png";
import rupraj from "assets/images/team/rupraj.png";
import barry from "assets/images/team/barry.png";


import shay from "assets/images/team/shay.png";
import lewis from "assets/images/team/lewis.png";

function Team() {
    return (
        <div className={styles.wrapper}>
            <div className="container-wrapper-2">
                <header className="mb-70px">
                    <h1 className="font-heading text-gradient text-center fs-48px mb-25px uppercase">
                        Our Business Team
                    </h1>
                    <p className="fs-14px white text-center lh-1_9">
                        This section of the team is in charge of managing our current real world businesses and furthering our
                        expansions into new areas. Our team is made up of industry experts in their chosen field ranging
                        from Real estate, Construction, Energy and finance.
                    </p>
                </header>
                <main className={styles.cards}>
                    <TeamCard img={scott} name="Scott Scherer" designation="Founder"
                              twitterLink="https://twitter.com/Scott05971932?t=heNczMDTBN6envK77pKFpg&s=09"
                              linkdenLink="https://www.linkedin.com/in/scott-scherer-b49865234"/>
                    <TeamCard img={harvey} name="Harvey McFaull" designation=""
                              twitterLink="https://twitter.com/h_mcfaull?t=uYBwVGCQfB5rb-AryzSq2A&s=09"
                              linkdenLink="https://www.linkedin.com/in/harvey-mcfaull-25b238236"/>
                    <TeamCard img={simon} name="Simon Henderson" designation=""
                              linkdenLink="https://www.linkedin.com/in/simon-henderson-a40b36237"/>
                    <TeamCard img={dalton} name="Jonathon Dalton" designation=""
                              linkdenLink=""/>
                    <TeamCard img={rupraj} name="Rupraj L Mohanty" designation=""
                              twitterLink="https://twitter.com/rupraj_26"
                              linkdenLink="https://www.linkedin.com/in/rupraj-lagnajeet-mohanty-ab501328"/>
                    <TeamCard img={paul} name="Paul Somerville" designation=""
                              twitterLink="https://twitter.com/5omerville"
                              linkdenLink="https://www.linkedin.com/in/5omerville/"/>

                </main>
            </div>
            <div className="container-wrapper-team">
                <header className="mb-70px">
                    <h1 className="font-heading text-gradient text-center fs-48px mb-25px uppercase">
                        Our Blockchain Team
                    </h1>
                    <p className="fs-14px white text-center lh-1_9">
                        This section of the team is in charge of everything we do within the Blockchain and Crypto space,
                        as well as the technical development and management of all of our Dapps, Applications and
                        websites for both sides of the business.
                    </p>
                </header>
                <main className={styles.cards}>
                    <TeamCard img={scott} name="Scott Scherer" designation="Founder"
                              twitterLink="https://twitter.com/Scott05971932?t=heNczMDTBN6envK77pKFpg&s=09"
                              linkdenLink="https://www.linkedin.com/in/scott-scherer-b49865234"/>
                    <TeamCard img={lewis} name="Lewis Collins" designation=""
                              linkdenLink="https://www.linkedin.com/in/lewis-collins-285489192/"/>
                    <TeamCard img={dravo} name="Dravo" designation=""
                              twitterLink="https://twitter.com/Slim_World_Andy"/>
                </main>
            </div>
            <div className="container-wrapper-team">
                <header className="mb-70px">
                    <h1 className="font-heading text-gradient text-center fs-48px mb-25px uppercase">
                        Advisors
                    </h1>
                    <p className="fs-14px white text-center lh-1_9">
                        These are our trusted advisors we're currently working with to achieve our goals.
                    </p>
                </header>
                <main className={styles.cards}>
                    <TeamCard img={barry} name="Barry J McGinn" designation="Advisor"
                              linkdenLink="https://www.linkedin.com/in/barrymcginn"/>
                    <TeamCard img={shay} name="Shay Tailor" designation="Property Advisor"
                              linkdenLink="https://www.linkedin.com/in/shay-tailor-321a43113"/>
                </main>
            </div>
        </div>


    );
}

export default Team;
