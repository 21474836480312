import React, { useEffect, useState } from "react";
import styles from "scss/layout/Navbar.module.scss";
import { Link } from "react-scroll";
import { IoMenu, IoClose } from "react-icons/io5";
import OutsideClickDetector from "hooks/OutsideClickDetector";
import logo from "assets/images/logo/logo.png";
import Button from "components/Button";



// function handleSwapLinkClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
//   event.preventDefault();
//   generateSwapRequest().then((swapRequest) => {
//     window.location.href = `https://swap.embr.com?${swapRequest}`;
//   });
// }

function Navbar() {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = OutsideClickDetector(() => setIsSidebarOpen(false));



  useEffect(() => {
    const handler = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    document.addEventListener("scroll", handler);
    return () => document.removeEventListener("scroll", handler);

  });

  // @ts-ignore
  return (
    <div className={`${styles.navbar} ${isScrolled ? styles.scrolled : null}`}>
      <div className="container-wrapper-2">
        <main>
            <div>
              <img src={logo} alt="" />
            </div>

          <button
            className={styles.hamburger}
            onClick={() => setIsSidebarOpen((val) => !val)}
          >
            <IoMenu />
          </button>

          <div
            ref={sidebarRef}
            className={`${styles.links} ${isSidebarOpen ? styles.open : null}`}
          >
            <button
              className={styles.closeIcon}
              onClick={() => setIsSidebarOpen((val) => !val)}
            >
              <IoClose color="white" />
            </button>
            <Link
              to="home"
              className="fs-14px white weight-5 pointer"
              onClick={() => setIsSidebarOpen(false)}
            >
              Home
            </Link>
            <Link
              to="aboutus"
              className="fs-14px white weight-5 pointer"
              onClick={() => setIsSidebarOpen(false)}
              offset={-160}
            >
              About Us
            </Link>
            <Link
              to="PropertyOwnership"
              className="fs-14px white weight-5 pointer"
              onClick={() => setIsSidebarOpen(false)}
              offset={-160}
            >
			Utilities
            </Link>
            <Link
              to="tokenomics"
              className="fs-14px white weight-5 pointer"
              onClick={() => setIsSidebarOpen(false)}
              offset={-160}
            >
              Tokenomics
            </Link>
            <Link
              to="roadmap"
              className="fs-14px white weight-5 pointer"
              onClick={() => setIsSidebarOpen(false)}
              offset={-160}
            >
              Roadmap
            </Link>
            <a
                href="https://checkout.embr.org/swap/bsc/0x0c4789fC849F9209e6c5104093cF884f681AE687"
                className="fs-14px white weight-5 pointer"
                target="_blank"
                rel="noreferrer"
            >
              Swap
            </a>


          </div>

          <div className={`black-screen ${isSidebarOpen ? "show" : ""}`}></div>
		  <div className={styles.buttons}>
				<a href="https://app.outoken.org/" target="_blank">
				<Button title="LAUNCH DAPP" variant="pink" />
				</a>
				</div>
        </main>
      </div>
    </div>
  );
}

export default Navbar;
